<template>
  <div>
    <div
      class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8 mt-6"
    >
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <img class="mx-auto h-auto w-48" src="../assets/logo.jpg" alt="ditf" />
        <h2 class="mt-10 text-center text-3xl font-extrabold text-gray-900">
          Sign In here
        </h2>
      </div>

      <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form class="space-y-6 form" @submit.prevent="login">
            <div
              id="succ"
              class="hidden bg-green-50 border-l-4 border-green-400 p-4"
            >
              <div class="flex">
                <div class="flex-shrink-0">
                  <!-- Heroicon name: check-circle -->
                  <svg
                    class="h-5 w-5 text-green-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <div class="ml-3">
                  <p id="succ-text" class="text-sm text-green-700">
                    Sie haben sich erfolgreich angemeldet.
                  </p>
                </div>
              </div>
            </div>

            <div
              id="err"
              class="hidden bg-gray-50 border-l-4 border-gray-400 p-4"
            >
              <div class="flex">
                <div class="flex-shrink-0">
                  <!-- Heroicon name: x-circle -->
                  <svg
                    class="h-5 w-5 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <div class="ml-3">
                  <p id="err-text" class="text-sm text-gray-700"></p>
                </div>
              </div>
            </div>

            <div>
              <label
                for="email"
                class="block text-sm font-medium text-gray-700"
              >
                E-Mail*
              </label>
              <div class="mt-1">
                <input
                  placeholder="E-Mail"
                  id="email"
                  name="email"
                  type="email"
                  autocomplete="email"
                  required
                  class="
                    appearance-none
                    block
                    w-full
                    px-3
                    py-2
                    border border-gray-300
                    rounded-md
                    shadow-sm
                    placeholder-gray-400
                    focus:outline-none
                    focus:ring-gray-500
                    focus:border-gray-500
                    sm:text-sm
                  "
                />
              </div>
            </div>

            <div>
              <label
                for="password"
                class="block text-sm font-medium text-gray-700"
              >
                Password*
              </label>
              <div class="mt-1">
                <input
                  placeholder="password"
                  id="password"
                  name="password"
                  type="password"
                  autocomplete="current-password"
                  required
                  class="
                    appearance-none
                    block
                    w-full
                    px-3
                    py-2
                    border border-gray-300
                    rounded-md
                    shadow-sm
                    placeholder-gray-400
                    focus:outline-none
                    focus:ring-gray-500
                    focus:border-gray-500
                    sm:text-sm
                  "
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                @click="checkInput()"
                class="
                  submit
                  w-full
                  flex
                  justify-center
                  py-2
                  px-4
                  border border-transparent
                  rounded-md
                  shadow-sm
                  text-sm
                  font-medium
                  text-white
                  bg-gray-700
                  hover:bg-gray-800
                  focus:outline-none
                  focus:ring-2 focus:ring-offset-2 focus:ring-gray-500
                "
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";

export default {
  name: "Login",
  methods: {
    checkInput() {
      let email = document.querySelector("#email").value;
      let password = document.querySelector("#password").value;

      if (email.length && password.length) {
        document.querySelector("#err").classList.add("hidden");
      } else {
        document.querySelector("#err").classList.remove("hidden");
        if (!email.length) {
          document.querySelector("#err-text").innerHTML =
            "Please enter your E-Mail.";
        }
        if (!password.length) {
          document.querySelector("#err-text").innerHTML =
            "Please enter your Password.";
        }
        if (!email.length && !password.length) {
          document.querySelector("#err-text").innerHTML =
            "Please fill in the fields";
        }
      }
    },
    login() {
      let email = document.querySelector("#email").value;
      let password = document.querySelector("#password").value;

      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(() => {
          document.querySelector("#succ").classList.remove("hidden");
          document.querySelector("#err").classList.add("hidden");
          document.querySelector(".form").reset();
          this.$router.push("/event");
          window.scrollTo(0, 0);
        })
        .catch((err) => {
          this.whichErrorIsIt(err);
          document.querySelector("#succ").classList.add("hidden");
          document.querySelector("#err").classList.remove("hidden");
        });
    },
    whichErrorIsIt(err) {
      switch (err.code) {
        case "auth/email-already-in-use":
          document.querySelector("#err-text").innerHTML =
            "The E-Mail is already in use";
          break;
        case "auth/weak-password":
          document.querySelector("#err-text").innerHTML =
            "The minimal length of the password is 6" +
            "and can't be too common";
          break;
        case "auth/invalid-email":
          document.querySelector("#err-text").innerHTML =
            "Please enter a valid E-Mail";
          break;
        default:
          document.querySelector("#err-text").innerHTML =
            "Something went wrong.";
          break;
      }
    },
  },
};
</script>

<style scoped></style>
